import React from 'react';
import { PageProps } from 'gatsby';
import { Home } from '../components/pages';
import Layout from '../components/layout/Layout';

export default function CategoriesPage({ ...props }: PageProps) {
  return (
    <Layout navigate={props.navigate}>
      <Home />
    </Layout>
  );
}
